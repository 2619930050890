export const theme = {
  colors: {
    primary: '#1E3A8A',    // 深蓝色，显得更专业
    secondary: '#2563EB',   // 亮蓝色，用于强调
    text: '#1F2937',       // 深灰色文字，提高可读性
    background: '#F8FAFC',  // 浅灰背景，更现代
    accent: '#3B82F6',     // 点缀色
    light: '#F1F5F9',      // 更浅的背景色
    dark: '#0F172A',       // 深色文字
    success: '#059669',    // 成功状态色
    border: '#E2E8F0',     // 边框颜色
    textLight: '#64748B',  // 浅色文字
    textDark: '#1E293B'    // 深色文字
  },
  fonts: {
    main: "'PingFang SC', 'Microsoft YaHei', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial",
  },
  fontSizes: {
    xs: '0.75rem',     // 12px
    sm: '0.875rem',    // 14px
    base: '1rem',      // 16px
    lg: '1.125rem',    // 18px
    xl: '1.25rem',     // 20px
    '2xl': '1.5rem',   // 24px
    '3xl': '1.875rem', // 30px
    '4xl': '2.25rem',  // 36px
    '5xl': '3rem',     // 48px
    '6xl': '3.75rem',  // 60px
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    none: 1,
    tight: 1.25,
    snug: 1.375,
    normal: 1.5,
    relaxed: 1.625,
    loose: 2,
  },
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
    wide: '1440px'
  },
  shadows: {
    small: '0 2px 8px rgba(0, 0, 0, 0.1)',
    medium: '0 4px 12px rgba(0, 0, 0, 0.1)',
    large: '0 8px 24px rgba(0, 0, 0, 0.1)',
    hover: '0 12px 32px rgba(0, 0, 0, 0.1)'
  },
  transitions: {
    default: 'all 0.3s ease',
    slow: 'all 0.6s ease',
    fast: 'all 0.2s ease'
  },
  spacing: {
    xs: '0.5rem',
    sm: '1rem',
    md: '1.5rem',
    lg: '2rem',
    xl: '3rem',
    '2xl': '4rem',
    '3xl': '6rem'
  }
}; 