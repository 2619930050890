import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VerifiedIcon from '@mui/icons-material/Verified';
import SupportIcon from '@mui/icons-material/Support';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SecurityIcon from '@mui/icons-material/Security';
import FactoryIcon from '@mui/icons-material/Factory';
import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';

const HeroSection = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.85)),
    url('https://qn.static.yancypipe.com/bj.jpg') center/cover;
  color: white;
  text-align: center;
  position: relative;
`;

const HeroContent = styled.div`
  max-width: 800px;
  padding: 2rem;
`;

const ProductsSection = styled.section`
  padding: 5rem 2rem;
  background: white;
  margin-top: 1rem;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2.2rem;
  color: ${props => props.theme.colors.primary};
  font-weight: ${props => props.theme.fontWeights.bold};
  letter-spacing: 0.5px;
  margin-top: 1rem;
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  max-width: 1200px;
  margin: 1rem auto 0;
  padding: 0 1rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`;

const ProductCard = styled(motion.div)`
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: ${props => props.theme.shadows.medium};
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: ${props => props.theme.transitions.default};
  
  &:hover {
    box-shadow: ${props => props.theme.shadows.hover};
  }
`;

const ProductImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 75%;
`;

const ProductImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ProductInfo = styled.div`
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    color: ${props => props.theme.colors.primary};
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    font-weight: ${props => props.theme.fontWeights.semibold};
  }

  p {
    color: ${props => props.theme.colors.textLight};
    font-size: 0.9rem;
    line-height: 1.5;
  }
`;

const Section = styled.section`
  padding: 6rem 2rem;
  background: ${props => props.background || 'white'};
`;

const ServicesSection = styled(Section)`
  background: ${props => props.theme.colors.background};
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1440px;
  margin: 0 auto;
`;

const ServiceCard = styled(motion.div)`
  background: white;
  padding: 2.5rem 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  }

  .icon {
    font-size: 3rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1.5rem;
  }

  h3 {
    color: ${props => props.theme.colors.primary};
    margin: 1rem 0;
    font-size: 1.3rem;
    font-weight: ${props => props.theme.fontWeights.semibold};
  }

  p {
    color: ${props => props.theme.colors.textLight};
    line-height: 1.6;
    font-size: 1rem;
  }
`;

const CertificationsSection = styled(Section)`
  text-align: center;
`;

const CertificationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1440px;
  margin: 2rem auto;
`;

const CertificationCard = styled(motion.div)`
  background: white;
  padding: 2.5rem 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  }

  .icon {
    font-size: 2.8rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1.5rem;
  }

  h4 {
    color: ${props => props.theme.colors.primary};
    margin-bottom: 0.8rem;
    font-size: 1.2rem;
    font-weight: ${props => props.theme.fontWeights.semibold};
  }

  p {
    color: ${props => props.theme.colors.textLight};
    font-size: 1rem;
    line-height: 1.6;
    text-align: center;
  }
`;

const services = [
  {
    icon: <EngineeringIcon className="icon" />,
    title: '定制化生产',
    description: '根据客户需求提供完全定制化的管道解决方案，确保产品完全符合您的使用要求。'
  },
  {
    icon: <SupportIcon className="icon" />,
    title: '技术支持',
    description: '专业的技术团队提供全方位支持，从产品选型到安装使用的每个环节都为您提供专业指导。'
  },
  {
    icon: <VerifiedIcon className="icon" />,
    title: '质量保证',
    description: '严格的质量管理体系，每件产品都经过多重检测，确保安全可靠。'
  },
  {
    icon: <LocalShippingIcon className="icon" />,
    title: '快速交付',
    description: '完善的生产线和库存管理，确保产品能够快速交付，满足客户的急需。'
  }
];

const certifications = [
  {
    icon: <WorkspacePremiumIcon className="icon" />,
    title: 'ISO 9001认证',
    description: '国际质量管理体系认证，确保产品质量管理的规范性和可靠性'
  },
  {
    icon: <SecurityIcon className="icon" />,
    title: 'CE认证',
    description: '欧盟安全认证，产品符合欧盟安全、健康和环保要求'
  },
  {
    icon: <FactoryIcon className="icon" />,
    title: '3C认证',
    description: '中国国家强制性产品认证，符合国家安全生产标准'
  }
];

const StyledHeroContent = styled(HeroContent)`
  position: relative;
  z-index: 2;
  background: rgba(0, 0, 0, 0.4);
  padding: 3.5rem 4rem;
  border-radius: 15px;
  
  h1 {
    font-weight: ${props => props.theme.fontWeights.bold};
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
    color: white;
  }

  .slogan {
    font-size: 1.8rem;
    font-weight: ${props => props.theme.fontWeights.semibold};
    letter-spacing: 1.5px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 1.5rem;
    color: ${props => props.theme.colors.accent};
  }

  .description {
    font-size: 1.2rem;
    font-weight: ${props => props.theme.fontWeights.medium};
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.95);
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 2.5rem 2rem;
    
    h1 {
      font-size: 2.2rem;
    }
    
    .slogan {
      font-size: 1.4rem;
    }
    
    .description {
      font-size: 1.1rem;
    }
  }
`;

const SectionSubtitle = styled.p`
  text-align: center;
  color: ${props => props.theme.colors.textLight};
  max-width: 800px;
  margin: 0 auto 2rem;
  font-size: 1.1rem;
  line-height: 1.8;
  letter-spacing: 0.3px;
`;

// 添加新的动画样式组件
const AnimatedCard = styled(motion.div)`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

const AnimatedIcon = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// 添加动画变体
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5
    }
  }
};

const iconVariants = {
  hover: {
    scale: 1.1,
    rotate: 5,
    transition: {
      duration: 0.3,
      type: "spring",
      stiffness: 300
    }
  }
};

function Home() {
  const { t } = useTranslation();

  // 更新产品展示数据，使用更稳定的图片链接
  const featuredProducts = [
    {
      id: 'corrugated',
      name: '波纹管系列',
      description: '高品质不锈钢波纹管，柔韧性好，抗震性能优异适用于各种工业场景',
      image: 'https://qn.static.yancypipe.com/8750398423_218640588_2.jpg'
    },
    {
      id: 'gas',
      name: '燃气管系列',
      description: '专业燃气输送管道，采用优质304不锈钢，安全可靠，使用寿命长',
      image: 'https://cbu01.alicdn.com/img/ibank/9682556260_218640588.jpg'
    },
    {
      id: 'accessories',
      name: '管道配件',
      description: '各类管道连接件、阀门、接头等配件，确保管道系统完整性和可靠性',
      image: 'https://cbu01.alicdn.com/img/ibank/9652012224_218640588.jpg'
    }
  ];

  return (
    <>
      <SEO />
      <HeroSection>
        <StyledHeroContent>
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, type: "spring", stiffness: 100 }}
            style={{ fontSize: '3.5rem' }}
          >
            {t('home.hero.title')}
          </motion.h1>
          <motion.p
            className="slogan"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            {t('home.hero.slogan')}
          </motion.p>
          <motion.p
            className="description"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            {t('home.hero.description')}
          </motion.p>
        </StyledHeroContent>
      </HeroSection>   

      <ProductsSection>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={containerVariants}
        >
          <SectionTitle>{t('home.products.title')}</SectionTitle>
          <SectionSubtitle>
            {t('home.products.subtitle')}
          </SectionSubtitle>
          <ProductsGrid>
            {Object.keys(t('home.products.items', { returnObjects: true })).map((key) => (
              <AnimatedCard
                key={key}
                variants={itemVariants}
                whileHover={{ 
                  y: -10,
                  transition: { duration: 0.3 }
                }}
              >
                <ProductCard>
                  <ProductImageContainer>
                    <ProductImage 
                      src={featuredProducts.find(p => p.id === key)?.image} 
                      alt={t(`home.products.items.${key}.name`)} 
                    />
                  </ProductImageContainer>
                  <ProductInfo>
                    <h3>{t(`home.products.items.${key}.name`)}</h3>
                    <p>{t(`home.products.items.${key}.description`)}</p>
                  </ProductInfo>
                </ProductCard>
              </AnimatedCard>
            ))}
          </ProductsGrid>
        </motion.div>
      </ProductsSection>

      <ServicesSection>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={containerVariants}
        >
          <SectionTitle>{t('home.services.title')}</SectionTitle>
          <SectionSubtitle>
            {t('home.services.subtitle')}
          </SectionSubtitle>
          <ServiceGrid>
            {Object.keys(t('home.services.items', { returnObjects: true })).map((key, index) => (
              <motion.div
                key={key}
                variants={itemVariants}
                whileHover={{ scale: 1.03 }}
              >
                <ServiceCard>
                  <AnimatedIcon whileHover="hover" variants={iconVariants}>
                    {services[index].icon}
                  </AnimatedIcon>
                  <h3>{t(`home.services.items.${key}.title`)}</h3>
                  <p>{t(`home.services.items.${key}.description`)}</p>
                </ServiceCard>
              </motion.div>
            ))}
          </ServiceGrid>
        </motion.div>
      </ServicesSection>

      <CertificationsSection>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={containerVariants}
        >
          <SectionTitle>{t('home.certifications.title')}</SectionTitle>
          <SectionSubtitle>
            {t('home.certifications.subtitle')}
          </SectionSubtitle>
          <CertificationGrid>
            {Object.keys(t('home.certifications.items', { returnObjects: true })).map((key, index) => (
              <motion.div
                key={key}
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
              >
                <CertificationCard>
                  <AnimatedIcon whileHover="hover" variants={iconVariants}>
                    {certifications[index].icon}
                  </AnimatedIcon>
                  <h4>{t(`home.certifications.items.${key}.title`)}</h4>
                  <p>{t(`home.certifications.items.${key}.description`)}</p>
                </CertificationCard>
              </motion.div>
            ))}
          </CertificationGrid>
        </motion.div>
      </CertificationsSection>
    </>
  );
}

export default Home; 