import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function SEO({ title, description, keywords, image }) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const currentLang = i18n.language;
  
  // 根据当前路径获取页面标题
  const getPageTitle = () => {
    const path = location.pathname;
    const companyName = currentLang === 'zh' 
      ? '宁波彦新管业有限公司'
      : 'Ningbo Yancy Pipe Industry Co., Ltd.';
    
    let pageTitle = '';
    
    switch(path) {
      case '/':
        pageTitle = currentLang === 'zh' 
          ? `${companyName} - 专业管道制造商`
          : currentLang === 'en'
            ? `${companyName} - Professional Pipe Manufacturer`
            : `${companyName} - Профессиональный производитель труб`;
        break;
      case '/products':
        pageTitle = currentLang === 'zh'
          ? `产品中心 | ${companyName}`
          : currentLang === 'en'
            ? `Products | ${companyName}`
            : `Продукты | ${companyName}`;
        break;
      case '/about':
        pageTitle = currentLang === 'zh'
          ? `关于我们 | ${companyName}`
          : currentLang === 'en'
            ? `About Us | ${companyName}`
            : `О нас | ${companyName}`;
        break;
      case '/contact':
        pageTitle = currentLang === 'zh'
          ? `联系我们 | ${companyName}`
          : currentLang === 'en'
            ? `Contact Us | ${companyName}`
            : `Контакты | ${companyName}`;
        break;
      default:
        pageTitle = currentLang === 'zh' 
          ? `${companyName} - 专业管道制造商`
          : currentLang === 'en'
            ? `${companyName} - Professional Pipe Manufacturer`
            : `${companyName} - Профессиональный производитель труб`;
    }
    return pageTitle;
  };

  const defaultTitle = getPageTitle();
  const defaultDescription = currentLang === 'zh'
    ? '宁波彦新管业有限公司专业生产燃气管、波纹管及配套管件，20年专注管道制造，品质铸就未来。'
    : currentLang === 'en'
      ? 'Ningbo Yancy Pipe Industry specializes in manufacturing gas pipes, corrugated pipes and fittings with 20 years of experience.'
      : 'Ningbo Yancy Pipe Industry специализируется на производстве газовых труб, гофрированных труб и фитингов с 20-летним опытом.';

  const defaultKeywords = currentLang === 'zh'
    ? '宁波彦新管业,燃气管,波纹管,不锈钢波纹管,燃气波纹管,金属软管,304不锈钢波纹管'
    : currentLang === 'en'
      ? 'Yancy Pipe,gas pipe,corrugated pipe,stainless steel pipe,metal hose,304 stainless steel pipe'
      : 'Yancy Pipe,газовая труба,гофрированная труба,труба из нержавеющей стали';

  const siteUrl = 'https://yancypipe.com';
  const canonicalUrl = `${siteUrl}${location.pathname}`;
  const metaImage = image || `${siteUrl}/LOGO.jpg`;

  return (
    <Helmet>
      <html lang={currentLang} />
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={metaImage} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      <meta name="twitter:image" content={metaImage} />

      {/* 替代语言链接 */}
      <link rel="alternate" hrefLang="zh" href={`${siteUrl}/zh${location.pathname}`} />
      <link rel="alternate" hrefLang="en" href={`${siteUrl}/en${location.pathname}`} />
      <link rel="alternate" hrefLang="ru" href={`${siteUrl}/ru${location.pathname}`} />
      <link rel="alternate" hrefLang="x-default" href={siteUrl} />
    </Helmet>
  );
}

export default SEO; 