import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AMap from '../../components/AMap';
import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';

const ContactContainer = styled.div`
  padding: 120px 0 4rem;
`;

const HeroSection = styled.section`
  background: linear-gradient(rgba(30, 58, 138, 0.9), rgba(30, 58, 138, 0.9)),
    url('https://qn.static.yancypipe.com/bj.jpg') center/cover;
  padding: 6rem 2rem;
  text-align: center;
  color: white;
  margin-bottom: 4rem;
`;

const PageTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: white;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.8;
`;

const ContentSection = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  gap: 4rem;
  min-height: 600px;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    min-height: auto;
  }
`;

const ContactInfoSection = styled.div`
  flex: 1;
  background: white;
  border-radius: 15px;
  box-shadow: ${props => props.theme.shadows.large};
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  height: 600px;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    height: auto;
  }
`;

const ContactTitle = styled.h2`
  color: ${props => props.theme.colors.primary};
  font-size: 1.8rem;
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  padding-bottom: 1rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: ${props => props.theme.colors.primary};
  }
`;

const ContactCardsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContactCards = styled.div`
  margin-bottom: 2rem;
`;

const ContactCard = styled(motion.div)`
  padding: 1.5rem;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  display: flex;
  align-items: center;
  gap: 1.5rem;

  &:last-child {
    border-bottom: none;
  }

  .icon {
    font-size: 2rem;
    color: ${props => props.theme.colors.primary};
    flex-shrink: 0;
  }

  .content {
    h3 {
      color: ${props => props.theme.colors.primary};
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }

    p {
      color: ${props => props.theme.colors.textLight};
      font-size: 1rem;
      margin: 0;
    }
  }
`;

const MapSection = styled.div`
  flex: 1.5;
  height: 600px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: ${props => props.theme.shadows.large};
  
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    height: 400px;
  }
`;

function Contact() {
  const { t } = useTranslation();

  const contactItems = [
    {
      icon: <LocationOnIcon className="icon" />,
      title: t('contact.info.address.title'),
      content: t('contact.info.address.content')
    },
    {
      icon: <PhoneIcon className="icon" />,
      title: t('contact.info.phone.title'),
      content: t('contact.info.phone.content')
    },
    {
      icon: <EmailIcon className="icon" />,
      title: t('contact.info.email.title'),
      content: t('contact.info.email.content')
    },
    {
      icon: <AccessTimeIcon className="icon" />,
      title: t('contact.info.hours.title'),
      content: [
        t('contact.info.hours.weekdays'),
        t('contact.info.hours.weekend')
      ]
    }
  ];

  return (
    <>
      <SEO />
      <ContactContainer>
        <HeroSection>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <PageTitle>{t('contact.hero.title')}</PageTitle>
            <PageSubtitle>
              {t('contact.hero.subtitle')}
            </PageSubtitle>
          </motion.div>
        </HeroSection>

        <ContentSection>
          <ContactInfoSection>
            <ContactTitle>{t('contact.info.title')}</ContactTitle>
            <ContactCardsContainer>
              <ContactCards>
                {contactItems.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <ContactCard>
                      {item.icon}
                      <div className="content">
                        <h3>{item.title}</h3>
                        {Array.isArray(item.content) ? (
                          item.content.map((line, i) => (
                            <p key={i}>{line}</p>
                          ))
                        ) : (
                          <p>{item.content}</p>
                        )}
                      </div>
                    </ContactCard>
                  </motion.div>
                ))}
              </ContactCards>
            </ContactCardsContainer>
          </ContactInfoSection>

          <MapSection>
            <AMap />
          </MapSection>
        </ContentSection>
      </ContactContainer>
    </>
  );
}

export default Contact; 