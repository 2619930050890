import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const SwitcherContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const LanguageButton = styled.button`
  background: none;
  border: none;
  color: ${({ $active, $scrolled, $isHome, theme }) => 
    $active 
      ? theme.colors.accent 
      : $scrolled || !$isHome 
        ? theme.colors.textDark 
        : 'white'};
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem;
  transition: all 0.3s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: ${({ $active }) => $active ? '100%' : '0'};
    height: 2px;
    background: ${props => props.theme.colors.accent};
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }

  &:hover {
    color: ${props => props.theme.colors.accent};
    
    &::after {
      width: 100%;
    }
  }
`;

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const isHome = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // 初始检查

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <SwitcherContainer>
      <LanguageButton
        $active={i18n.language === 'zh'}
        $scrolled={scrolled}
        $isHome={isHome}
        onClick={() => i18n.changeLanguage('zh')}
      >
        中文
      </LanguageButton>
      <LanguageButton
        $active={i18n.language === 'en'}
        $scrolled={scrolled}
        $isHome={isHome}
        onClick={() => i18n.changeLanguage('en')}
      >
        EN
      </LanguageButton>
      <LanguageButton
        $active={i18n.language === 'ru'}
        $scrolled={scrolled}
        $isHome={isHome}
        onClick={() => i18n.changeLanguage('ru')}
      >
        RU
      </LanguageButton>
    </SwitcherContainer>
  );
}

export default LanguageSwitcher; 