import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher';

const Nav = styled.nav`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: ${({ $scrolled, $isHome }) => 
    $scrolled 
      ? 'rgba(255, 255, 255, 0.97)' 
      : $isHome 
        ? 'transparent'
        : 'rgba(255, 255, 255, 0.97)'};
  backdrop-filter: ${({ $scrolled, $isHome }) => 
    $scrolled || !$isHome ? 'blur(20px)' : 'none'};
  transition: all 0.4s ease;
  height: 80px;
  box-shadow: ${({ $scrolled }) => 
    $scrolled ? '0 4px 30px rgba(0, 0, 0, 0.05)' : 'none'};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(
      120deg,
      ${props => props.theme.colors.primary},
      ${props => props.theme.colors.accent},
      ${props => props.theme.colors.secondary}
    );
    opacity: ${({ $scrolled }) => $scrolled ? 1 : 0};
    transition: opacity 0.4s ease;
  }
`;

const NavContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  padding: 4px 0;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1000;

  &:hover {
    transform: scale(1.02);
  }
`;

const LogoImage = styled.img`
  height: 220%;
  width: auto;
  object-fit: contain;
  transform: scale(0.45);
  transform-origin: left center;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    height: 160px;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -2rem;
    width: 1px;
    height: 24px;
    background: linear-gradient(
      to bottom,
      transparent,
      ${props => props.theme.colors.border},
      transparent
    );
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${({ $scrolled, $isHome, theme }) => 
    $scrolled || !$isHome 
      ? theme.colors.textDark 
      : 'white'};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: 1rem;
  position: relative;
  padding: 0.5rem 0.8rem;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 6px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: ${props => props.theme.colors.accent};
    transition: all 0.3s ease;
    transform: translateX(-50%);
  }

  &:hover {
    color: ${props => props.theme.colors.accent};
    background: rgba(59, 130, 246, 0.05);
    
    &::after {
      width: calc(100% - 1.6rem);
    }
  }

  &.active {
    color: ${props => props.theme.colors.accent};
    background: rgba(59, 130, 246, 0.08);
    
    &::after {
      width: calc(100% - 1.6rem);
    }
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: ${({ $scrolled, $isHome, theme }) => 
    $scrolled || !$isHome 
      ? theme.colors.textDark 
      : 'white'};
  font-weight: ${props => props.theme.fontWeights.medium};
  font-size: 1rem;
  position: relative;
  padding: 0.5rem 0.8rem;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.3rem;

  &::after {
    content: '↗';
    font-size: 1.1em;
    transition: transform 0.3s ease;
    margin-left: 2px;
  }

  &:hover {
    color: ${props => props.theme.colors.accent};
    background: rgba(59, 130, 246, 0.05);
    transform: translateY(-1px);
    
    &::after {
      transform: translate(2px, -2px);
    }
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${({ $scrolled, $isHome }) => 
    $scrolled || !$isHome ? '#1E293B' : 'white'};
  font-size: 2rem;
  cursor: pointer;
  z-index: 1000;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
  }
`;

const MobileMenu = styled(motion.div)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  padding: 6rem 2rem 2rem;
  z-index: 999;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
`;

const MobileNavLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.textDark};
  font-size: 1.2rem;
  font-weight: ${props => props.theme.fontWeights.medium};
  padding: 1rem;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover, &.active {
    background: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.primary};
  }
`;

const MobileExternalLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.textDark};
  font-size: 1.2rem;
  font-weight: ${props => props.theme.fontWeights.medium};
  padding: 1rem;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    background: ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.primary};
  }

  &::after {
    content: '↗';
    font-size: 1.1em;
  }
`;

const MobileLanguageSwitcher = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const isHome = location.pathname === '/';
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // 当路由改变时关闭移动菜单
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  const menuItems = [
    { path: '/', label: t('nav.home') },
    { path: '/products', label: t('nav.products') },
    { path: '/about', label: t('nav.about') },
    { path: '/contact', label: t('nav.contact') },
    { 
      path: 'https://nbyxgy.1688.com/', 
      label: t('nav.mall'),
      external: true 
    },
  ];

  return (
    <Nav $scrolled={scrolled} $isHome={isHome}>
      <NavContainer>
        <LogoContainer to="/">
          <LogoImage 
            src="https://qn.static.yancypipe.com/wp-content/uploads/2020/07/logo100.png" 
            alt="宁波彦新管业" 
          />
        </LogoContainer>
        <NavLinks>
          {menuItems.map((item) => (
            item.external ? (
              <ExternalLink
                key={item.path}
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                $scrolled={scrolled}
                $isHome={isHome}
              >
                {item.label}
              </ExternalLink>
            ) : (
              <NavLink 
                key={item.path} 
                to={item.path}
                $scrolled={scrolled}
                $isHome={isHome}
                className={location.pathname === item.path ? 'active' : ''}
              >
                {item.label}
              </NavLink>
            )
          ))}
          <LanguageSwitcher />
        </NavLinks>
        <MobileMenuButton
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          $scrolled={scrolled}
          $isHome={isHome}
        >
          {mobileMenuOpen ? '×' : '☰'}
        </MobileMenuButton>
        <AnimatePresence>
          {mobileMenuOpen && (
            <MobileMenu
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {menuItems.map((item) => (
                item.external ? (
                  <MobileExternalLink
                    key={item.path}
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.label}
                  </MobileExternalLink>
                ) : (
                  <MobileNavLink 
                    key={item.path}
                    to={item.path}
                    className={location.pathname === item.path ? 'active' : ''}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.label}
                  </MobileNavLink>
                )
              ))}
              <MobileLanguageSwitcher>
                <LanguageSwitcher />
              </MobileLanguageSwitcher>
            </MobileMenu>
          )}
        </AnimatePresence>
      </NavContainer>
    </Nav>
  );
}

export default Navbar; 