import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
`;

const StyledMap = styled.div`
  width: 100%;
  height: 100%;
`;

function AMap() {
  const mapInstance = useRef(null);
  const markerInstance = useRef(null);
  const infoWindowInstance = useRef(null);

  useEffect(() => {
    // 加载高德地图脚本
    const script = document.createElement('script');
    script.src = `https://webapi.amap.com/maps?v=2.0&key=1fef69d2c9e862b26588f1cf68ba6c1f&securityJsCode=a9dcf08002f29ecfae7707289ad31ede&plugin=AMap.Scale,AMap.ToolBar`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // 创建地图实例
      mapInstance.current = new window.AMap.Map('map-container', {
        zoom: 15,
        center: [121.259199, 30.002058],
        viewMode: '3D'
      });

      // 自定义标记点样式
      const markerContent = `
        <div style="background-color: #1E3A8A; padding: 8px 16px; border-radius: 20px; box-shadow: 0 2px 8px rgba(0,0,0,0.2);">
          <span style="color: white; font-weight: 500; font-size: 14px;">宁波彦新管业</span>
        </div>
      `;

      // 添加标记点
      markerInstance.current = new window.AMap.Marker({
        position: [121.259199, 30.002058],
        content: markerContent,
        anchor: 'bottom-center',
        offset: new window.AMap.Pixel(0, 0)
      });

      mapInstance.current.add(markerInstance.current);

      // 添加控件
      mapInstance.current.addControl(new window.AMap.Scale({
        position: 'LB'
      }));
      
      mapInstance.current.addControl(new window.AMap.ToolBar({
        position: 'RB'
      }));

      // 自定义信息窗体样式
      const infoWindowContent = `
        <div style="padding: 15px; max-width: 300px;">
          <h3 style="margin: 0 0 10px; color: #1E3A8A; font-size: 16px; font-weight: 600;">
            宁波彦新管业有限公司
          </h3>
          <p style="margin: 0; line-height: 1.5; color: #4B5563; font-size: 14px;">
            浙江省余姚市陆埠镇五马工业功能区创优西路4号
          </p>
          <div style="margin-top: 10px; padding-top: 10px; border-top: 1px solid #E5E7EB;">
            <a href="https://uri.amap.com/marker?position=121.259199,30.002058" 
               target="_blank" 
               style="color: #3B82F6; text-decoration: none; font-size: 14px;">
              导航到这里 →
            </a>
          </div>
        </div>
      `;

      // 添加信息窗体
      infoWindowInstance.current = new window.AMap.InfoWindow({
        content: infoWindowContent,
        offset: new window.AMap.Pixel(0, -20),
        anchor: 'bottom-center'
      });

      // 点击标记时打开信息窗体
      markerInstance.current.on('click', () => {
        infoWindowInstance.current.open(mapInstance.current, markerInstance.current.getPosition());
      });

      // 默认打开信息窗体
      infoWindowInstance.current.open(mapInstance.current, markerInstance.current.getPosition());
    };

    return () => {
      // 清理地图实例和相关资源
      if (infoWindowInstance.current) {
        infoWindowInstance.current.close();
      }
      if (markerInstance.current) {
        markerInstance.current.remove();
      }
      if (mapInstance.current) {
        mapInstance.current.destroy();
      }
      // 移除脚本
      const scriptElement = document.querySelector(`script[src^="https://webapi.amap.com/maps"]`);
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  }, []);

  return (
    <MapContainer>
      <StyledMap id="map-container" />
    </MapContainer>
  );
}

export default AMap; 