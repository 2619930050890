import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: ${props => props.theme.fonts.main};
    color: ${props => props.theme.colors.text};
    line-height: ${props => props.theme.lineHeights.relaxed};
    background-color: ${props => props.theme.colors.background};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior: smooth;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.colors.textDark};
    line-height: ${props => props.theme.lineHeights.tight};
    margin-bottom: ${props => props.theme.spacing.md};
    font-weight: ${props => props.theme.fontWeights.bold};
  }

  h1 {
    font-size: ${props => props.theme.fontSizes['5xl']};
    letter-spacing: -0.02em;
  }

  h2 {
    font-size: ${props => props.theme.fontSizes['4xl']};
    letter-spacing: -0.01em;
  }

  h3 {
    font-size: ${props => props.theme.fontSizes['3xl']};
  }

  p {
    margin-bottom: ${props => props.theme.spacing.md};
    color: ${props => props.theme.colors.text};
    font-size: ${props => props.theme.fontSizes.lg};
    line-height: ${props => props.theme.lineHeights.relaxed};
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    font-weight: ${props => props.theme.fontWeights.medium};
    transition: ${props => props.theme.transitions.default};

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }

  button {
    cursor: pointer;
    font-family: inherit;
    font-weight: ${props => props.theme.fontWeights.medium};
    transition: ${props => props.theme.transitions.default};
  }

  section {
    padding: ${props => props.theme.spacing['3xl']} 0;
  }

  .container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 ${props => props.theme.spacing.lg};
  }

  .section-title {
    font-size: ${props => props.theme.fontSizes['4xl']};
    font-weight: ${props => props.theme.fontWeights.bold};
    text-align: center;
    margin-bottom: ${props => props.theme.spacing.xl};
    color: ${props => props.theme.colors.textDark};
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      bottom: -${props => props.theme.spacing.xs};
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 3px;
      background: ${props => props.theme.colors.primary};
    }
  }

  .section-subtitle {
    font-size: ${props => props.theme.fontSizes.xl};
    color: ${props => props.theme.colors.textLight};
    text-align: center;
    max-width: 800px;
    margin: 0 auto ${props => props.theme.spacing.xl};
    line-height: ${props => props.theme.lineHeights.relaxed};
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    html {
      font-size: 14px;
    }

    section {
      padding: ${props => props.theme.spacing['2xl']} 0;
    }

    h1 {
      font-size: ${props => props.theme.fontSizes['4xl']};
    }

    h2 {
      font-size: ${props => props.theme.fontSizes['3xl']};
    }

    h3 {
      font-size: ${props => props.theme.fontSizes['2xl']};
    }

    p {
      font-size: ${props => props.theme.fontSizes.base};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    .container {
      padding: 0 ${props => props.theme.spacing.md};
    }
  }
`; 