import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import EngineeringIcon from '@mui/icons-material/Engineering';
import VerifiedIcon from '@mui/icons-material/Verified';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SupportIcon from '@mui/icons-material/Support';
import HistoryIcon from '@mui/icons-material/History';
import GroupsIcon from '@mui/icons-material/Groups';
import FactoryIcon from '@mui/icons-material/Factory';
import SEO from '../../components/SEO';

const AboutContainer = styled.div`
  padding: 120px 0 4rem;
`;

const HeroSection = styled.section`
  background: linear-gradient(rgba(30, 58, 138, 0.9), rgba(30, 58, 138, 0.9)),
    url('https://cbu01.alicdn.com/img/ibank/O1CN01m3JM4n1Bs2jSHzFxz_!!0-0-cib.jpg') center/cover;
  padding: 6rem 2rem;
  text-align: center;
  color: white;
  margin-bottom: 4rem;
`;

const PageTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: white;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.8;
`;

const Section = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const CompanyIntro = styled(Section)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const IntroImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  box-shadow: ${props => props.theme.shadows.large};
`;

const IntroContent = styled.div`
  h2 {
    color: ${props => props.theme.colors.primary};
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: ${props => props.theme.fontWeights.bold};
  }

  p {
    color: ${props => props.theme.colors.textLight};
    margin-bottom: 1.5rem;
    line-height: 1.8;
    font-size: 1.1rem;
  }
`;

const AdvantagesSection = styled(Section)`
  background: ${props => props.theme.colors.light};
`;

const SectionTitle = styled.h2`
  text-align: center;
  color: ${props => props.theme.colors.primary};
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const SectionDescription = styled.p`
  text-align: center;
  color: ${props => props.theme.colors.textLight};
  max-width: 800px;
  margin: 0 auto 4rem;
  font-size: 1.1rem;
  line-height: 1.8;
`;

const AdvantagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const AdvantageCard = styled(motion.div)`
  padding: 2.5rem;
  background: white;
  border-radius: 15px;
  box-shadow: ${props => props.theme.shadows.medium};
  text-align: center;
  transition: ${props => props.theme.transitions.default};

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${props => props.theme.shadows.large};
  }

  .icon {
    font-size: 3rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1.5rem;
  }

  h3 {
    color: ${props => props.theme.colors.primary};
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-weight: ${props => props.theme.fontWeights.semibold};
  }

  p {
    color: ${props => props.theme.colors.textLight};
    line-height: 1.6;
  }
`;

const MilestoneSection = styled(Section)`
  text-align: center;
`;

const TimelineGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const TimelineCard = styled(motion.div)`
  padding: 2rem;
  background: white;
  border-radius: 15px;
  box-shadow: ${props => props.theme.shadows.medium};

  .year {
    font-size: 1.8rem;
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.fontWeights.bold};
    margin-bottom: 1rem;
  }

  .icon {
    font-size: 2.5rem;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 1rem;
  }

  p {
    color: ${props => props.theme.colors.textLight};
    line-height: 1.6;
  }
`;

function About() {
  const { t } = useTranslation();

  const advantages = [
    {
      icon: <EngineeringIcon className="icon" />,
      key: 'manufacturing'
    },
    {
      icon: <VerifiedIcon className="icon" />,
      key: 'quality'
    },
    {
      icon: <AutorenewIcon className="icon" />,
      key: 'innovation'
    },
    {
      icon: <SupportIcon className="icon" />,
      key: 'service'
    }
  ];

  const milestones = [
    {
      year: '2000',
      icon: <FactoryIcon className="icon" />,
      key: '2000'
    },
    {
      year: '2020',
      icon: <VerifiedIcon className="icon" />,
      key: '2020'
    },
    {
      year: '2021',
      icon: <GroupsIcon className="icon" />,
      key: '2021'
    },
    {
      year: '2023',
      icon: <HistoryIcon className="icon" />,
      key: '2023'
    }
  ];

  return (
    <>
      <SEO />
      <AboutContainer>
        <HeroSection>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <PageTitle>{t('about.hero.title')}</PageTitle>
            <PageSubtitle>
              {t('about.hero.subtitle')}
            </PageSubtitle>
          </motion.div>
        </HeroSection>

        <CompanyIntro>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <IntroImage 
              src="https://cbu01.alicdn.com/img/ibank/O1CN01m3JM4n1Bs2jSHzFxz_!!0-0-cib.jpg" 
              alt={t('about.company.title')} 
            />
          </motion.div>
          
          <IntroContent>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h2>{t('about.company.title')}</h2>
              <p>{t('about.company.description1')}</p>
              <p>{t('about.company.description2')}</p>
            </motion.div>
          </IntroContent>
        </CompanyIntro>

        <AdvantagesSection>
          <SectionTitle>{t('about.advantages.title')}</SectionTitle>
          <SectionDescription>
            {t('about.advantages.subtitle')}
          </SectionDescription>
          <AdvantagesGrid>
            {advantages.map((advantage, index) => (
              <AdvantageCard
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                {advantage.icon}
                <h3>{t(`about.advantages.items.${advantage.key}.title`)}</h3>
                <p>{t(`about.advantages.items.${advantage.key}.description`)}</p>
              </AdvantageCard>
            ))}
          </AdvantagesGrid>
        </AdvantagesSection>

        <MilestoneSection>
          <SectionTitle>{t('about.milestones.title')}</SectionTitle>
          <SectionDescription>
            {t('about.milestones.subtitle')}
          </SectionDescription>
          <TimelineGrid>
            {milestones.map((milestone, index) => (
              <TimelineCard
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                {milestone.icon}
                <div className="year">{milestone.year}</div>
                <p>{t(`about.milestones.items.${milestone.key}.description`)}</p>
              </TimelineCard>
            ))}
          </TimelineGrid>
        </MilestoneSection>
      </AboutContainer>
    </>
  );
}

export default About; 