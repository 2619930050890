import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import WaterIcon from '@mui/icons-material/Water';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import SEO from '../../components/SEO';

const ProductsContainer = styled.div`
  padding: 120px 0 4rem;
`;

const HeroSection = styled.section`
  background: linear-gradient(rgba(30, 58, 138, 0.9), rgba(30, 58, 138, 0.9)),
    url('https://qn.static.yancypipe.com/bj.jpg') center/cover;
  padding: 6rem 2rem;
  text-align: center;
  color: white;
  margin-bottom: 4rem;
`;

const PageTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: white;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const PageDescription = styled.p`
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
`;

const ContentSection = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const CategoryNav = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 4rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 1rem;
`;

const CategoryButton = styled.button`
  padding: 1rem 2rem;
  border: none;
  background: ${({ $active, theme }) => $active ? theme.colors.primary : 'white'};
  color: ${({ $active, theme }) => $active ? 'white' : theme.colors.textDark};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: ${props => props.theme.fontWeights.medium};
  box-shadow: ${props => props.theme.shadows.small};
  border: 1px solid ${({ $active, theme }) => $active ? theme.colors.primary : theme.colors.border};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
  data-category: ${props => props.dataCategory};

  .icon {
    font-size: 1.3rem;
  }

  &:hover {
    background: ${props => props.theme.colors.primary};
    color: white;
    transform: translateY(-2px);
    box-shadow: ${props => props.theme.shadows.medium};
  }
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2.5rem;
  padding: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

const ProductCard = styled(motion.div)`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: ${props => props.theme.shadows.medium};
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: ${props => props.theme.transitions.default};
`;

const ProductImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-bottom: 1px solid ${props => props.theme.colors.border};
`;

const ProductDetails = styled.div`
  padding: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: white;
`;

const ProductTitle = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: ${props => props.theme.fontWeights.semibold};
`;

const ProductDescription = styled.p`
  color: ${props => props.theme.colors.textLight};
  margin-bottom: 2rem;
  line-height: 1.6;
  font-size: 1.1rem;
`;

const ProductSpecs = styled.div`
  background: ${props => props.theme.colors.light};
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: auto;

  .spec-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
    color: ${props => props.theme.colors.textDark};
    font-size: 1rem;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      color: ${props => props.theme.colors.primary};
      font-size: 1.2rem;
      flex-shrink: 0;
    }
  }
`;

// 添加动画变体
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5
    }
  }
};

function Products() {
  const { t } = useTranslation();
  const [activeCategory, setActiveCategory] = useState('all');

  const categories = [
    { 
      id: 'all', 
      name: t('products.categories.all'), 
      icon: <SettingsIcon /> 
    },
    { 
      id: 'gas', 
      name: t('products.categories.gas'), 
      icon: <LocalGasStationIcon />
    },
    { 
      id: 'corrugated', 
      name: t('products.categories.corrugated'), 
      icon: <WaterIcon />
    },
    { 
      id: 'accessories', 
      name: t('products.categories.accessories'), 
      icon: <BuildIcon />
    },
  ];

  const products = [
    {
      id: 'water_pipe',
      category: 'corrugated',
      image: 'https://qn.static.yancypipe.com/wp-content/uploads/2020/04/9674083361_218640588.jpg'
    },
    {
      id: 'copper_pipe',
      category: 'corrugated',
      image: 'https://qn.static.yancypipe.com/dujin.jpg'
    },
    {
        id: 'hydraulic_pipe',
        image: 'https://qn.static.yancypipe.com/bowenguan.jpg',
        category: 'corrugated'
      },
   
    {
      id: 'gas_pipe',
      image: 'https://qn.static.yancypipe.com/wp-content/uploads/2020/05/9648069199_218640588.jpg',
      category: 'gas'
    },
    {
      id: 'fixed_gas_pipe',
      image: 'https://qn.static.yancypipe.com/8629256447_218640588_1.jpg',
      category: 'gas'
    },
    {
      id: '304_gas_pipe',
      image: 'https://cbu01.alicdn.com/img/ibank/9682556260_218640588.jpg',
      category: 'gas'
    },
    {
        id: 'cutter',
        category: 'accessories',
        image: 'https://cbu01.alicdn.com/img/ibank/9652012224_218640588.jpg'
    },
    {
      id: 'pipe_nut',
      image: 'https://cbu01.alicdn.com/img/ibank/9658678004_218640588.jpg',
      category: 'accessories'
    },
    {
      id: 'inner_nut',
      image: 'https://cbu01.alicdn.com/img/ibank/9680417403_218640588.jpg',
      category: 'accessories'
    }
  ];

  const filteredProducts = activeCategory === 'all'
    ? products
    : products.filter(product => product.category === activeCategory);

  return (
    <>
      <SEO />
      <ProductsContainer>
        <HeroSection>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <PageTitle>{t('products.hero.title')}</PageTitle>
            <PageDescription>
              {t('products.hero.description')}
            </PageDescription>
          </motion.div>
        </HeroSection>

        <ContentSection>
          <CategoryNav>
            {categories.map(category => (
              <CategoryButton
                key={category.id}
                $active={activeCategory === category.id}
                onClick={() => setActiveCategory(category.id)}
                data-category={category.id}
              >
                {category.icon}
                <span>{category.name}</span>
              </CategoryButton>
            ))}
          </CategoryNav>

          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <ProductGrid>
              {filteredProducts.map(product => (
                <motion.div
                  key={product.id}
                  variants={itemVariants}
                  whileHover={{ y: -10 }}
                  transition={{ duration: 0.3 }}
                >
                  <ProductCard>
                    <ProductImage src={product.image} alt={t(`products.items.${product.id}.name`)} />
                    <ProductDetails>
                      <ProductTitle>{t(`products.items.${product.id}.name`)}</ProductTitle>
                      <ProductDescription>
                        {t(`products.items.${product.id}.description`)}
                      </ProductDescription>
                      <ProductSpecs>
                        {Object.keys(t(`products.items.${product.id}.specs`, { returnObjects: true })).map((spec, index) => (
                          <div key={index} className="spec-item">
                            <CheckCircleIcon />
                            <span>{t(`products.items.${product.id}.specs.${spec}`)}</span>
                          </div>
                        ))}
                      </ProductSpecs>
                    </ProductDetails>
                  </ProductCard>
                </motion.div>
              ))}
            </ProductGrid>
          </motion.div>
        </ContentSection>
      </ProductsContainer>
    </>
  );
}

export default Products; 