import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en.json';
import translationZH from './locales/zh.json';
import translationRU from './locales/ru.json';

const resources = {
  en: {
    translation: translationEN
  },
  zh: {
    translation: translationZH
  },
  ru: {
    translation: translationRU
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'zh',
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lng',
      caches: ['localStorage'],
      cookieMinutes: 160000
    },
    interpolation: {
      escapeValue: false
    }
  });

i18n.on('languageChanged', (lng) => {
  const url = new URL(window.location.href);
  url.searchParams.set('lng', lng);
  window.history.pushState({}, '', url.toString());
});

export default i18n; 